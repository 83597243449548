import { courseTypeFilterMatch } from '~/utils/helpers'
import { reactive, toRefs, onMounted } from 'vue'

export interface CourseType {
  title: string
  value: string[]
  count: number
}
export interface CourseTypeFiltersProps {
  courseTypes: [string, number][]
  courseAreaColour?: string
  selectedCourseType: CourseType | null
  totalCourseCount: number | null
}
// TODO: At a later refactor date. Replace emit callback logic with either v-model method or a watch on main component.
type EmitSignature = (arg: CourseType | undefined | null) => void

export default function (
  props: CourseTypeFiltersProps,
  emitCallback: EmitSignature
) {
  const { courseTypes } = toRefs(props)

  const sortedCourseTypes: CourseType[] = reactive([
    {
      title: 'Short Courses',
      value: [
        'Statement of Attainment',
        'TAFE Statement',
        'TAFE Plus Statement'
      ],
      count: 0
    },
    {
      title: 'Certificates',
      value: [
        'Certificate I',
        'Certificate II',
        'Certificate III',
        'Certificate IV'
      ],
      count: 0
    },
    {
      title: 'Diploma Level',
      value: ['Diploma', 'Advanced Diploma'],
      count: 0
    },
    {
      title: 'Degree Level',
      value: [
        'Bachelor',
        'Graduate Certificate',
        'Graduate Diploma',
        'Undergraduate Certificate',
        'Associate Degree',
        'Degree'
      ],
      count: 0
    },
    {
      title: 'Fee Free',
      value: ['Fee Free'],
      count: 0
    }
  ])

  const router = useRouter()
  const route = useRoute()

  onMounted(() => {
    sortedCourseTypes.forEach((sorted, i) => {
      sortedCourseTypes[i].count = 0
      courseTypes.value.forEach((val) => {
        if (courseTypeFilterMatch(sorted, val[0])) {
          sortedCourseTypes[i].count += val[1]
        }
      })
    })
    setSelectedCourseTypeByQueryString(
      (route.query?.filter as string)?.toLowerCase()
    )
  })

  const transformCourseTitle = (title: string | undefined) => {
    return title?.toLowerCase().replace(' ', '-') || null
  }

  const setSelectedCourseTypeByQueryString = (query: string) => {
    if (query) {
      const filteredCourseType = sortedCourseTypes.find(
        (c) => transformCourseTitle(c.title) === query
      )

      emitCallback(filteredCourseType)
    }
  }
  const updateQuery = async (
    selectedCourseType: CourseType | undefined | null
  ) => {
    await router.replace({
      query: {
        ...route.query,
        filter: transformCourseTitle(selectedCourseType?.title) || null
      }
    })
  }

  return {
    sortedCourseTypes,
    updateQuery
  }
}
